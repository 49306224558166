export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Cadastros',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Usuários',
        route: 'list-users',
      },
      {
        title: 'Perfis de Usuários',
        route: 'list-perfil-usuario',
      },
      {
        title: 'Cidades de atendimento',
        route: 'list-cidades',
      },
      {
        title: 'Centrais',
        route: 'list-centrais',
      },
      {
        title: 'Status',
        route: 'list-status',
      },
      /* {
        title: 'Tipos de Avaliação Clínica',
        route: 'list-avaliacao-clinica',
      },
      {
        title: 'Tipos de Acompanhante',
        route: 'list-tipo-acompanhante',
      },
      {
        title: 'Tipos de Transporte',
        route: 'list-tipo-transporte',
      },
      {
        title: 'Tipos de Auxilio Locomoção',
        route: 'list-tipo-auxilio-locomocao',
      },
      {
        title: 'Tipos de Endereços',
        route: 'list-tipo-domicilio',
      },
      {
        title: 'Tipos de Unidades de Saúde',
        route: 'list-tipo-unidade-saude',
      },
      {
        title: 'Tipos de Veículos',
        route: 'list-tipo-veiculo',
      },
      {
        title: 'Tipos de Cuidados de Produtos',
        route: 'list-tipo-cuidado-produtos',
      },
      {
        title: 'Tipos de Estabelecimentos',
        route: 'list-tipo-estabelecimento',
      },
      {
        title: 'Tipos de Unidades de Medidas',
        route: 'list-tipo-unidade-medida',
      }, */
      /* {
        title: 'Motivos de Transporte',
        route: 'list-motivo-transporte',
      },
      {
        title: 'Natureza de Transporte',
        route: 'list-natureza-transporte',
      },
      {
        title: 'Situação do Transporte',
        route: 'list-situacao-transporte',
      },
      {
        title: 'Estabelecimentos',
        route: 'list-estabelecimento',
      },
      {
        title: 'CIDs',
        route: 'list-cids',
      },
      {
        title: 'CBOs',
        route: 'list-cbos',
      },
      {
        title: 'Veículos',
        route: 'list-veiculo',
      },
      {
        title: 'Pacientes',
        route: 'list-paciente',
      },
      {
        title: 'Produtos',
        route: 'list-produtos',
      },
      {
        title: 'Categorias de Produtos',
        route: 'list-categoria-produto',
      },
      {
        title: 'UF',
        route: 'list-ufs',
      }, */
      /* {
        title: 'Municipios',
        route: 'list-municipios',
      },
      {
        title: 'Unidades de Saúde',
        route: 'list-unidade-saude',
      }, */
    ],
  },
  /* {
    title: 'Solicitar Transporte',
    route: 'list-transporte',
    icon: 'HeartIcon',
  },
  {
    title: 'Aprovações',
    route: 'list-aprovacoes',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Programações',
    route: 'list-programacoes',
    icon: 'CalendarIcon',
  },
  {
    title: 'Calendário',
    route: 'calendario',
    icon: 'CalendarIcon',
  }, */
  {
    title: 'Atendimentos',
    route: 'list-atendimentos',
    icon: 'FileTextIcon',
  },
]
